import VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/App"),
  },
  {
    path: "/kbeditor",
    name: "Graphs",
    component: () => import("@/components/Graphs"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
