<template>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <div class="container">
      <b-navbar-brand href="#">
        <svg width="100" height="100">
          <use :xlink:href="require('@/assets/logo3.svg') + '#logo'" />
        </svg>
        EFESTO-5W with privacy, security and safety issues detector
      </b-navbar-brand>
      <b-navbar-toggle target="navbarResponsive"><span class="navbar-toggler-icon"></span></b-navbar-toggle>
      <b-collapse id="navbarResponsive" is-nav>
        <b-navbar-nav class="ms-auto">
          <b-nav-item :active="$route.name === 'Home'" href="/">Home</b-nav-item>
          <b-nav-item :active="$route.name === 'Graphs'" href="/kbeditor">Knowledge Base Editor</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: "Header",
  data: function() {
    return {
      tab: 0,
    };
  },

  mounted: function() {},

  computed: {},

  methods: {},
};
</script>
<style scoped></style>
