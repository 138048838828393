<template>
  <div>
    <Header />
    <loading :active.sync="isLoading" :is-full-page="fullPage" loader="bars" />
    <router-view :key="$route.fullPath" @startLoad="updateLoading" />
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Header from "@/components/Header.vue";

export default {
  name: "App",
  data: function() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },
  components: { Loading, Header },
  methods: {
    updateLoading(loading) {
      this.isLoading = loading;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;700&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Readex Pro", sans-serif !important;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #323232;
  border: none;
  color: #e5e5e5;
}

.style-chooser-input {
  color: #394066;
  background: #dfe5fb;
}

.style-chooser .vs__search,
.style-chooser .vs__open,
.style-chooser .vs__dropdown-menu {
  min-width: 60px;
  white-space: nowrap;
  color: #e5e5e5;
}

.style-chooser .vs__selected {
  color: #e5e5e5;
}

.style-chooser .vs__dropdown-menu > * {
  color: #e5e5e5;
  background: #323232;
}

.style-chooser .vs__dropdown-menu > *:hover {
  background: #323232;
  font-weight: bold;
}

.v-select {
  min-width: 60px;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #e5e5e5;
}

.form-control:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6) !important;
  border-color: rgba(0, 0, 0, 1) !important;
}
</style>
